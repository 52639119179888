/** Load fonts from Adobe Typekit (Nunito) */
/* @import url("https://use.typekit.net/phi0cod.css"); */

html, body, #root, .App {
  width: 100%;
  height: 100%;
}

div.homepage-container {
  width: 100%;
  height: 100%;
  background-color: rgb(17, 17, 17);
  overflow: hidden;
  position: relative;
}

/** OurBabies page styling */

/** Grid styling */
div.grid {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
}

.grid-item {
  background-color: grey;
  /* height: 100%; */
  flex-grow: 1;
  padding: 15px;
  /* width: 50%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  display: flex;
  align-items: center;
  text-align: center;
}

.grid-item-text {
  height: min-content;
  font-family: "Europa", sans-serif;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  /* font-size: 25px; */
}

/** Reduce opacity for placeholder text on inputs. */
*::-webkit-input-placeholder {
    color: red;
}
*:-moz-placeholder {
    /* FF 4-18 */
    color:  #80808078;
    opacity: 1;
}
*::-moz-placeholder {
    /* FF 19+ */
    color: #80808078;
    opacity: 1;
}
*:-ms-input-placeholder {
    /* IE 10+ */
    color: #80808078;
}
*::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #80808078;
}
*::placeholder {
    /* modern browser */
    color: #80808078;
}

input,
label,
select,
button,
textarea
{
	margin:0;
	border:0;
	padding:0;
	display:inline-block;
	vertical-align:middle;
	white-space:normal;
	background:none;
	line-height:1;

	/* Browsers have different default form fonts */
	font-size:13px;
	font-family:Arial;
}

/* Remove the stupid outer glow in Webkit */
input:focus
{
	outline:0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea
{
	-webkit-box-sizing:content-box;
	-moz-box-sizing:content-box;
	box-sizing:content-box;
}

/* These elements are usually rendered a certain way by the browser */
button,
input[type=reset],
input[type=button],
input[type=submit],
input[type=checkbox],
input[type=radio],
select
{
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}

/* Text Inputs
-----------------------------------------------*/

input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week]
{
}

/* Button Controls
-----------------------------------------------*/

input[type=checkbox],
input[type=radio]
{
	width:13px;
	height:13px;
}

/* File Uploads
-----------------------------------------------*/

input[type=file]
{

}

/* Search Input
-----------------------------------------------*/

/* Make webkit render the search input like a normal text field */
input[type=search]
{
	-webkit-appearance:textfield;
	-webkit-box-sizing:content-box;
}

/* Turn off the recent search for webkit. It adds about 15px padding on the left */
::-webkit-search-decoration
{
	display:none;
}

/* Buttons
-----------------------------------------------*/

button,
input[type="reset"],
input[type="button"],
input[type="submit"]
{
	/* Fix IE7 display bug */
	overflow:visible;
	width:auto;
}

/* IE8 and FF freak out if this rule is within another selector */
::-webkit-file-upload-button
{
	padding:0;
	border:0;
	background:none;
}

/* Textarea
-----------------------------------------------*/

textarea
{
	/* Move the label to the top */
	vertical-align:top;

	/* Turn off scroll bars in IE unless needed */
	overflow:auto;
}

/* Selects
-----------------------------------------------*/

select
{

}

select[multiple]
{
	/* Move the label to the top */
	vertical-align:top;
}
button.reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

/* Remove outline */
button.reset:focus {
  outline: 0;
}

.low-opacity {
  opacity: 0.7;
  transition: 0.3s ease-in-out;
}

.hover-fade-in:hover {
  opacity: 1;
}

/* Navigation styling */
a.nav-link:hover div.hamburger-link-line {
  transform: translateX(30px);
}

a.nav-link:hover p.hamburger-numbering-text {
  /* transform: scale(1.1); */
  opacity: 0.5;
}

/** Homepage styling. */
div.homepage-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.hero-content-container {
  display: flex;
  /* max-width: 500px; */
  flex-wrap: wrap;
  justify-content: center;
  /* width: 50%; */
  z-index: 1;
}

div.hero-content-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
  max-width: 400px;
}

/** DynamicText styling */
div.dynamic-text-container {
  height: 40px;
  margin-bottom: 10px;
}

div.dynamic-text-container p {
  font-family: proxima-soft, sans-serif;
  line-height: 1;
  color: #e2e8f2;
  font-size: 40px;
  margin: 0;
  width: max-content;
}

/** Background graphics styling **/
svg.hero-background-graphic-one {
  position: absolute;
  height: 200vh;
  width: 200vw;
  left: -50%;
  top: -12%;
  opacity: 0.47;
}

svg.hero-background-graphic-two {
  position: absolute;
  opacity: 0.05;
  top: 0%;
  left: -38%;
}

/** Hamburger Menu Styling. */
div.hamburger-links-container {
  position: absolute;
  right: 37px;
  top: 90px;
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 3;
}

.hamburger-link-text {
  white-space: nowrap;
  margin: 0;
  display: block;
  width: max-content;
  font-size: 19px;
  font-weight: 300;
  opacity: 0.7;
}

.hamburger-numbering-text {
  margin: auto 0px auto 0px;
  font-family: "Abhaya Libre", sans-serif;
  font-size: 21px;
  opacity: 0.2;
  transition: 0.3s ease-in-out;
}

div.hamburger-link-line {
  width: -webkit-fill-available;
  border-top: solid white 1px;
  height: 1px;
  opacity: 0.2;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.15s ease-out;
}

/** Break points for contact page. */
